<template>
<v-dialog v-model="dialog" scrollable :fullscreen="$vuetify.breakpoint.mobile">
          <template v-slot:activator="{ on, attrs }">
            <v-btn small text color="black lighten-2" dark v-bind="attrs" v-on="on" v-if="!href">
                {{btnText}}
            </v-btn>
                        <a href="javascript:void(0)" small text color="black lighten-2" dark v-bind="attrs" v-on="on" v-else>
                {{btnText}}
            </a>
        </template>
  <v-card>
    <v-card-title>
      <v-row>
        <v-col>
          Пользовательское соглашение
        </v-col>
        <v-col cols="auto">
                    <v-btn fab small @click="dialog=false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-col>
      </v-row>


    </v-card-title>
    <v-divider></v-divider>
    <v-card-text class="text-body-1 grey--text text--darken-3 pa-5">

      <div class="text-h6 black--text py-3">Пользовательское Соглашение</div>
      <p>Настоящее Пользовательское Соглашение (Далее Соглашение) регулирует отношения между владельцем
        https://llcpriority.ru/ (далее Приоритет или Администрация) с одной стороны и пользователем сайта с другой.</p>
      <p>Сайт Приоритет не является средством массовой информации.</p>

      <p>
        Используя сайт, Вы соглашаетесь с условиями данного соглашения.</p>
      <p> <b>Если Вы не согласны с условиями данного соглашения, не используйте сайт Приоритет!</b></p>


      <div class="text-h6 black--text py-3">Права и обязанности сторон</div>
      <p>Пользователь имеет право:</p>
      <ol>
        <li> - осуществлять поиск информации на сайте</li>
        <li> - получать информацию на сайте</li>
        <li> - копировать информацию на другие сайты с указанием источника</li>
        <li> - использовать информацию сайта в личных некоммерческих целях</li>
      </ol>

      <div class="text-h6 black--text py-3">Администрация имеет право:</div>
      <ol>
        <li>- удалять учетные записи</li>
      </ol>
      <div class="text-h6 black--text py-3">Пользователь обязуется:</div>
      <ol>
        <li> - обеспечивать сохранность личных данных от доступа третьих лиц</li>
        <li> - не распространять информацию, которая направлена на пропаганду войны, разжигание национальной, расовой
          или религиозной ненависти и вражды, а также иной информации, за распространение которой предусмотрена
          уголовная или административная ответственность</li>
        <li> - не нарушать работоспособность сайта</li>
        <li> - не передавать в пользование свою учетную запись и/или логин и пароль своей учетной записи третьим лицам
        </li>
        <li> - не регистрировать учетную запись от имени или вместо другого лица за исключением случаев, предусмотренных
          законодательством РФ</li>
        <li> - не использовать скрипты (программы) для автоматизированного сбора информации и/или взаимодействия с
          Сайтом и его Сервисами</li>
      </ol>
      <div class="text-h6 black--text py-3">Администрация обязуется:</div>
      <p>- поддерживать работоспособность сайта за исключением случаев, когда это невозможно по независящим от
        Администрации причинам.</p>

      <div class="text-h6 black--text py-3">Ответственность сторон</div>
      <ol>
        <li> - администрация не несёт ответственность за несовпадение ожидаемых Пользователем и реально полученных услуг
        </li>
        <li> - администрация не несет никакой ответственности за услуги, предоставляемые третьими лицами</li>
        <li> - в случае возникновения форс-мажорной ситуации (боевые действия, чрезвычайное положение, стихийное
          бедствие и т. д.) Администрация не гарантирует сохранность информации, размещённой Пользователем, а также
          бесперебойную работу информационного ресурса</li>
      </ol>



    </v-card-text>
  </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: ['btnText','href'],
  name: "agreement",
  data(){
    return {
      dialog:false
    }
  }
}
</script>

<style scoped>
p {
  padding-left: 20px;
  margin-bottom: 5px;
  margin-top: 5px;
}

li {
  padding-left: 10px;
  padding-bottom: 10px;
  list-style-type: none;

}
</style>