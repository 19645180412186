<template>
    <v-dialog v-model="dialog" width="600" style="z-index:1000" scrollable :fullscreen="$vuetify.breakpoint.mobile">
        <template v-slot:activator="{ on, attrs }">
            <v-btn small text color="black lighten-2" dark v-bind="attrs" v-on="on">
                Условия оплаты
            </v-btn>
        </template>

        <v-card>
            <v-card-title class="text-h5 grey lighten-2">
                Условия оплаты<v-spacer/>
                <v-btn fab small @click="dialog=false"><v-icon>mdi-close</v-icon></v-btn>
            </v-card-title>

            <v-card-text class="text-body-1 mt-7">
                <p>Для оплаты с помощью банковской карты, выполните вход в личный кабинет, перейдите в раздел "Оплата"  и нажмите
                    кнопку "Оплата заказа банковской картой".</p>
                <p>Оплата происходит через ПАО СБЕРБАНК с использованием банковских карт следующих платёжных систем:</p>
                <v-row class="text-center my-5">
                    <v-col>

                        <img width="70" height="70" src="/match/mir.svg" class="mr-3" />
                        <div class="text-caption">МИР</div>
                    </v-col>
                    <v-col>

                        <img width="70" height="70" src="/match/visa.svg" class="mr-3" />
                        <div class="text-caption">VISA International</div>
                    </v-col>
                    <v-col>
                        <img width="70" height="70" src="/match/mastercard.svg" class="mr-3" />
                        <div class="text-caption">Mastercard Worldwide</div>
                    </v-col>
                    <v-col>
                        <img width="70" height="70" src="/match/JCB_logo.svg" class="mr-3" />
                        <div class="text-caption">JCB</div>
                        
                    </v-col>

                </v-row>

                <p>Для оплаты (ввода реквизитов Вашей карты) Вы будете перенаправлены на платёжный шлюз ПАО СБЕРБАНК.
                    Соединение с платёжным шлюзом и передача информации осуществляется в защищённом режиме с
                    использованием протокола шифрования SSL. В случае если Ваш банк поддерживает технологию безопасного
                    проведения интернет-платежей Verified By Visa, MasterCard SecureCode, MIR Accept, J-Secure, для
                    проведения платежа также может потребоваться ввод специального пароля.</p>

                <p>Настоящий сайт поддерживает 256-битное шифрование. Конфиденциальность сообщаемой персональной
                    информации обеспечивается ПАО СБЕРБАНК. Введённая информация не будет предоставлена третьим лицам за
                    исключением случаев, предусмотренных законодательством РФ. Проведение платежей по банковским картам
                    осуществляется в строгом соответствии с требованиями платёжных систем МИР, Visa Int., MasterCard
                    Europe Sprl, JCB.</p>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    data: () => ({
        dialog: false
    })
}
</script>

<style>
</style>