import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{staticStyle:{"z-index":"99999"},attrs:{"fullscreen":""},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c(VCard,[_c(VCardActions,{staticClass:"justify-space-between grey lighten-3"},[_c('h4',[_vm._v("Просмотр файла")]),_c(VBtn,{attrs:{"small":"","fab":"","color":"red","dark":""},on:{"click":function($event){_vm.dialog = false}}},[_c(VIcon,[_vm._v("mdi-close")])],1)],1),_c(VDivider),_c(VCardText,{staticClass:"ma-0 pa-0"},[_c('embed',{staticStyle:{"width":"100%","height":"calc(100vh - 65px)"},attrs:{"src":_vm.doc.file}})])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }