<template>
    <v-dialog v-model="dialog" width="600" style="z-index:1000">
        <template v-slot:activator="{ on, attrs }">
            <v-btn text small color="black lighten-2" dark v-bind="attrs" v-on="on">
                Условия возврата
            </v-btn>
        </template>

        <v-card>
            <v-card-title class="text-h5 grey lighten-2">
                Возврат товара <v-spacer/>
                <v-btn fab small @click="dialog=false"><v-icon>mdi-close</v-icon></v-btn>
            </v-card-title>

            <v-card-text class="text-body-1 mt-7">
                <p>

Срок возврата товара надлежащего качества составляет 30 дней с момента получения товара.
</p>
<p>
Возврат переведённых средств, производится на ваш банковский счёт в течение 5-30 рабочих дней (срок зависит от банка, который выдал вашу банковскую карту).</p>

            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    data: () => ({
        dialog: false
    })
}
</script>

<style>
</style>