export default {
    url: 'https://cabinet.academynb.ru/oauth',

    params: {
        clientId: '424559b3-19b6-4450-bea2-145a69ca8fb1',
        redirectUri: 'https://llcpriority.ru/login',
        responseType: 'code',
        scopes: ["phone","name","surname","patronymic"],
        state: {},
    }
}