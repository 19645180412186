import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'index',
    component: () => import(/* webpackChunkName: "about" */ '../views/Index.vue')
  },
  {
    path: '/login',
    name: 'login',
    meta: { auth: false },
    component:  () => import('../views/Login')
  },
  {
    meta: { auth: true },
    path: '/cabinet',
    name: 'cabinet',
    component:  () => import('../views/cabinet')
  },
  {
    meta: { auth: true },
    path: '/area/:id',
    name: 'area',
    component:  () => import('../views/Area')
  },
  {
    meta: { auth: true },
    path: '/theory',
    name: 'theory',
    component:  () => import('../views/application/theory')
  },
  {
    path: '/admin',
    name: 'admin',
    component:  () => import('../views/admin')
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
