<template>
  <v-app>

    <v-app-bar app color="white">
      <v-app-bar-nav-icon @click="navBar = !navBar" v-if="$vuetify.breakpoint.mobile"></v-app-bar-nav-icon>
      <div class="d-flex align-center">
        <v-img alt="logo" class="shrink mr-2" contain src="/match/logo.png" transition="scale-transition" width="40" />
      </div>

      <span class="black--text"><span v-if="!$vuetify.breakpoint.mobile">Общество с ограниченной ответственностью
        </span><span v-else>ООО </span>«Приоритет»</span>
      <v-spacer v-if="!$vuetify.breakpoint.mobile"></v-spacer>
      <div v-if="!$vuetify.breakpoint.mobile">
        <v-btn @click="toTop()" to="/" text color="black" class="mx-2">Главная
        </v-btn>
        <v-btn text to="/login" v-if="$auth.user() == null" color="black">Вход</v-btn>
        <v-btn text color="black" v-if="$auth.user() != null" to="/cabinet">Личный кабинет</v-btn>
        <v-btn text color="black" v-if="$auth.check() && $auth.user().isAdmin" to="/admin">Панель управления</v-btn>
        <v-btn text color="black" v-if="$auth.user() != null" @click="logout()">Выход</v-btn>
      </div>
    </v-app-bar>
    <v-navigation-drawer v-model="navBar" absolute temporary>
      <v-list nav dense>
        <v-list-item link @click="toTop()" to="/">
          <v-list-item-icon>
            <v-icon>mdi-home</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Главная</v-list-item-title>
        </v-list-item>
        <v-list-item link to="/login" v-if="$auth.user() == null">
          <v-list-item-icon>
            <v-icon>mdi-login-variant</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Вход</v-list-item-title>
        </v-list-item>
        <v-list-item link v-if="$auth.user() != null" to="/cabinet">
          <v-list-item-icon>
            <v-icon>mdi-account</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Личный кабинет</v-list-item-title>
        </v-list-item>
        <v-list-item link v-if="$auth.check() && $auth.user().isAdmin" to="/admin">
          <v-list-item-icon>
            <v-icon>mdi-cog</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Панель управления</v-list-item-title>
        </v-list-item>
        <v-list-item v-if="$auth.user() != null" @click="logout()">
          <v-list-item-icon>
            <v-icon>mdi-logout-variant</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Выход</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <v-main>

      <router-view />
    </v-main>
    <v-footer>
      <v-container>
        <v-row align="center">
          <v-col>
            <div v-bind:class="$vuetify.breakpoint.mobile ? 'text-center' : 'text-left'">
              <div class="mt-3" style="width: 120px; height: 120px; display:inline;">
                <img width="50" height="50" src="/match/mir.svg" class="mr-3" />
              </div>
              <div class="mt-3 ml-2" style="width: 120px; height: 120px; display:inline;">
                <img width="50" height="50" src="/match/visa.svg" class="mr-3" />
              </div>
              <div class="mt-3 ml-2" style="width: 120px; height: 120px; display:inline;">
                <img width="50" height="50" src="/match/mastercard.svg" class="mr-3" />
              </div>
              <div class="mt-3 ml-2" style="width: 120px; height: 120px; display:inline;">
                <img width="50" height="50" src="/match/JCB_logo.svg" class="mr-3" />
              </div>
            </div>
          </v-col>
          <v-col>
            <div v-bind:class="$vuetify.breakpoint.mobile ? 'text-center' : 'text-right'">
              <p class=" my-1">
                <payment />
              </p>
              <p class=" my-1">
                <refund />
              </p>
              <p class=" my-1">
                <argeement btnText="Пользовательское соглашение" />
              </p>
              <p class=" my-1"><v-btn small text to="/privacy">Обработка персональных данных</v-btn></p>
              <p class=" my-1">
                <v-btn small text color="black lighten-2" dark @click="$refs.mobileGuide.dialog = true">
                  Руководство Пользователя - Приложение Экзамен ППР
                </v-btn>
              </p>
              <p class=" my-1">
                <v-btn small text color="black lighten-2" dark @click="$refs.guide.dialog = true">
                  Руководство Пользователя - Экзамен ППР онлайн
                </v-btn>
              </p>
            </div>
          </v-col>
        </v-row>
        <div class="text-center">
          {{ new Date().getFullYear() }} — <strong>Общество с ограниченной ответственностью «Приоритет»</strong>
        </div>
      </v-container>
    </v-footer>
    <FileView :doc="{ file: '/data/mobileGuide.pdf' }" ref="mobileGuide" />
    <FileView :doc="{ file: '/data/Guide.pdf' }" ref="guide" />
  </v-app>
</template>

<style>
.main {
  padding-top: 300px;
}
</style>


<script>
import payment from '@/components/payment.vue'
import refund from '@/components/refund.vue'
import argeement from '@/components/argeement.vue'
import FileView from '@/components/FileView.vue'
export default {
  name: 'App',

  data: () => ({
    navBar: false,
    dialog: false
  }),
  components: {
    payment,
    refund,
    argeement,
    FileView
  },
  methods: {
    logout() {
      this.$auth.logout()
    },
    toTop() {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      })
    }
  }
};
</script>
