<template>
  <v-dialog v-model="dialog" fullscreen style="z-index:99999">
    <v-card >
      <v-card-actions class="justify-space-between grey lighten-3">
        <h4>Просмотр файла</h4>
        <v-btn small @click="dialog = false" fab color="red" dark
        ><v-icon>mdi-close</v-icon></v-btn
        >
      </v-card-actions>
      <v-divider />
      <v-card-text
          class="ma-0 pa-0"
      >
        <embed
            :src="doc.file"
            style="width: 100%; height: calc(100vh - 65px)"
        />

      </v-card-text>
    </v-card>
  </v-dialog>

</template>
<script>
export default {
  props:['doc'],
  data() {
    return {
      dialog: false
    }
  },
  computed:{
    size(){
      const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB']
      if (this.doc.size === 0) return 'n/a'
      const i = parseInt(Math.floor(Math.log(this.doc.size) / Math.log(1024)), 10)
      if (i === 0) return `${this.doc.size} ${sizes[i]})`
      return `${(this.doc.size / (1024 ** i)).toFixed(1)} ${sizes[i]}`
    }
  }
}
</script>
